import React from 'react';
import Section from '../section';
import SummaryItem from '../summary-item';
import { useStaticQuery, graphql } from 'gatsby';

const SectionSkills = () => {
  // Fetch the skills from site metadata
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          skills {
            name
            description
            link
          }
        }
      }
    }
  `);

  const skills = data.site.siteMetadata.skills;

  // Render the skills section
  return (
    <Section title="Skills">
      {skills.length > 0 ? (
        skills.map(skill => (
          <SummaryItem
            key={skill.name}
            name={skill.name}
            description={skill.description}
            link={skill.link}
            internal={true} // Mark the link as internal
          />
        ))
      ) : (
        <p>No skills found to display.</p>
      )}
    </Section>
  );
};

export default SectionSkills;

