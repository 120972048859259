import React from 'react';

import Section from '../section';
import SummaryItem from '../summary-item';

const SectionProjects = ({ projects }) => {
  if (!projects.length) return null;

  return (
    <Section title="Projects">
      {projects.map((project) => {
        // Check if the link is internal (doesn't start with "http")
        const isInternalLink = !project.link.startsWith('http');

        return (
          <SummaryItem
            key={project.name}
            name={project.name}
            description={project.description}
            link={project.link}
            internal={isInternalLink} // Pass internal prop based on link type
          />
        );
      })}
    </Section>
  );
};

export default SectionProjects;
